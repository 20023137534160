import { Fragment, useEffect, useState } from "react";
import { APColumn, APExpanded, APRow, APScrollView, APSizedBox } from "../layout";
import { APText } from "./APText";
import { APPalette } from "../utils";
import React from "react";
import { DownIcon, UpIcon } from "../icons";
import { Box } from "@mui/material";

type PromiseCallback = () => void | Promise<unknown>

interface SubItemProps {
    label: string,
    value: string,
    onClick?: PromiseCallback,
}

interface MenuItemProps {
    icon: JSX.Element,
    label: string,
    value: string,
    onClick?: PromiseCallback,
    subItems?: SubItemProps[]
}

export interface APSideNavProps {
    menuItems: MenuItemProps[],
    initialValue?: string,
    onChange?: (value?: string) => void | Promise<unknown>,
    /**
     * @default 230 
     */
    width?: number,
}

export default function APSideNav({
    width = 230,
    initialValue,
    menuItems,
    onChange,
}: APSideNavProps) {
    /// Selected tab of the side nav
    const [selectedTab, setSelectedTab] = useState<string | undefined>(initialValue);

    useEffect(() => {
        if (selectedTab && onChange) {
            /// Not calling onChange when initialValue is same as the selectedValue
            if (selectedTab !== initialValue) {
                onChange(selectedTab)
            }
        }
    }, [selectedTab])

    // Can also be treated as controlled component
    useEffect(() => setSelectedTab(initialValue), [initialValue])

    return (
        <APColumn
            crossAxisAlignment="stretch"
            mainAxisSize="max"
            style={{
                minWidth: width,
                maxWidth: width,
                padding: "16px 0px",
                backgroundColor: APPalette.white,
            }}
        >
            <APExpanded>
                <APScrollView>
                    <APColumn crossAxisAlignment="stretch">
                        {
                            menuItems.map((item, index) => {
                                /// In case of sub items, zIndex will make sure the elements is coming from behind the other element
                                return (
                                    <Fragment key={item.value}>
                                        <MenuItem
                                            style={{ zIndex: index + 2 }}
                                            item={item}
                                            initialValue={selectedTab}
                                            onClick={setSelectedTab}
                                        />
                                        {
                                            (index !== menuItems.length - 1) &&
                                            <APSizedBox height="8px" style={{ backgroundColor: APPalette.white, zIndex: index + 2 }} />
                                        }
                                    </Fragment>
                                )
                            })
                        }
                    </APColumn>
                </APScrollView>
            </APExpanded>
        </APColumn>
    )
}

function MenuItem({ style, item, initialValue, onClick }: { style?: React.CSSProperties, item: MenuItemProps, initialValue?: string, isSelected?: boolean, onClick: (value?: string) => void }) {
    /// Selected tab of the sub menu
    const [selectedTab, setSelectedTab] = useState<string>()
    const [isExpanded, setIsExpanded] = useState<boolean | undefined>(item.subItems ? false : undefined);

    let isSelected = initialValue?.split('/')[0] === item.value,
        isPseudoSelect = item.subItems ? isSelected ? true : false : undefined;

    useEffect(() => {
        /// When unselecting the tab, it should collapse the sub menu 
        if (!isSelected && item.subItems) {
            setIsExpanded(false)
            setSelectedTab(undefined)
        }

        /// When selecting the tab, it should 
        if (isSelected && item.subItems) {
            setIsExpanded(true)
            setSelectedTab(initialValue?.split('/')[1])
        }
    }, [initialValue])

    return (
        <Box sx={{
            height: 40 + (isExpanded ? 40 * item.subItems!.length + (8 * item.subItems!.length - 1) : 0),
            transition: 'all .3s ease',
            backgroundColor: APPalette.white,
            ...style
        }}>
            <APColumn crossAxisAlignment="stretch" gap="8px" mainAxisSize="max">
                <SideNavItem
                    icon={item.icon}
                    label={item.label}
                    isSelected={isSelected}
                    isPseudoSelected={isPseudoSelect}
                    isExpanded={isExpanded}
                    onClick={async () => {
                        if (item.subItems) {
                            setIsExpanded(prev => !prev)
                        } else {
                            onClick(item.value)
                            if (item.onClick) {
                                await item.onClick()
                            }
                        }
                    }}
                />
                <APColumn
                    crossAxisAlignment="stretch"
                    gap="8px"
                    style={{
                        visibility: isExpanded ? "visible" : "collapse",
                        opacity: isExpanded ? 1 : 0,
                        transition: 'visibility .15s,opacity .15s',
                    }}
                >
                    {
                        item.subItems?.map(subItem => (
                            <SideNavItem
                                key={subItem.value}
                                label={subItem.label}
                                isSelected={isSelected && subItem.value === selectedTab}
                                onClick={async () => {
                                    setSelectedTab(subItem.value)
                                    onClick(item.value + "/" + subItem.value)
                                    if (subItem.onClick) {
                                        await subItem.onClick()
                                    }
                                }}
                            />
                        ))
                    }
                </APColumn>
            </APColumn>
        </Box>
    )
}

function SideNavItem({ icon, label, isPseudoSelected, isSelected, isExpanded, onClick }: { icon?: JSX.Element, label: string, isPseudoSelected?: boolean, isSelected?: boolean, isExpanded?: boolean, onClick: PromiseCallback }) {
    const [isHovered, setIsHovered] = useState(false);

    let color = APPalette["grey-600"];
    let backgroundColor = APPalette.white;

    if (isHovered) {
        color = APPalette["brand-300"]
        backgroundColor = APPalette["grey-50"]
    }

    if (isSelected) {
        backgroundColor = APPalette["brand-100"]
        color = APPalette["brand-300"]
    }

    if (isPseudoSelected) {
        backgroundColor = APPalette.white;
    }

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onClick}
            style={{
                cursor: "pointer",
                backgroundColor,
                boxShadow: `-2px 0px 0px 0px ${(isSelected && !isPseudoSelected) ? APPalette["brand-300"] : backgroundColor} inset`,
                transition: 'backgroundColor .15s,borderRight .15s',
                transitionDelay: '0.15s'
            }}
        >
            <APRow style={{ padding: "8px 32px" }} gap="8px">
                <APExpanded>
                    <APRow mainAxisSize="min" gap="8px">
                        {
                            icon ?
                                React.cloneElement(icon, { size: 16, color })
                                :
                                <APSizedBox width="16px" />
                        }
                        <APExpanded>
                            <APText variant="paragraph-large" maxLines={1} color={color} style={{ userSelect: "none" }}>{label}</APText>
                        </APExpanded>
                    </APRow>
                </APExpanded>

                {
                    isExpanded != undefined ?
                        <Box style={{ display: "flex", rotate: isExpanded ? "-180deg" : '0deg', transition: "rotate .3s" }}>
                            <DownIcon size={16} color={color} />
                        </Box>
                        :
                        null
                }
            </APRow>
        </div>
    )
}