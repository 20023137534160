import ArrowDropDownCircleOutlined from "@mui/icons-material/ArrowDropDownCircleOutlined";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Settings from "@mui/icons-material/Settings";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import { APRow, APButton, APAsyncButton, sleep, APFormSubmit, APGrid, APSwitcher, APMenu, showSnackbar, APDropdownMenu, APActionsMenu, APMoreMenu, APDropdownIconMenu, APDateRange, APNumberRange, APAmountRange, APFilterMenu, APDropdownFilter, NArray, APSizedBox, APPillButton, MailIcon, APPalette, APFilterMenuV2 } from "ap-components";
import { useState } from "react";
import { DemoItem, DemoPage, DemoSection } from "./Common";
import APGroupedDropdownFilter from "ap-components/src/menus/APGroupedFilterMenu/APGroupedDropdownFilter";
import APDropdownSearch from "ap-components/src/menus/APDropdownSearch";

export default function Buttons() {

    return (
        <DemoPage label="Buttons">
            <DemoSection label='Basic'>
                <DemoItem label="Primary Button" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APButton size="small" onClick={() => { }} >Button</APButton>
                        <APButton onClick={() => { }} >Button</APButton>
                        <APButton leading={<ChevronRight />} size="large" onClick={() => { }} >Button</APButton>
                        <APButton size="large" >Button</APButton>
                    </APRow>
                </DemoItem>
                <DemoItem label="Secondary Button" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APButton type="secondary" size="small" color="primary" onClick={() => { }} >Button</APButton>
                        <APButton trailing={<ChevronLeft />} type="secondary" onClick={() => { }} >Button</APButton>
                        <APButton type="secondary" size="large" onClick={() => { }} >Button</APButton>
                        <APButton type="secondary" size="large" >Button</APButton>
                    </APRow>
                </DemoItem>
                <DemoItem label="Positive Button" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APButton type="positive" size="small" onClick={() => { }} >Button</APButton>
                        <APButton trailing={<ChevronLeft />} type="positive" onClick={() => { }} >Button</APButton>
                        <APButton type="positive" size="large" onClick={() => { }} >Button</APButton>
                        <APButton type="positive" size="large" >Button</APButton>
                    </APRow>
                </DemoItem>
                <DemoItem label="Negative Button" >
                    <APRow mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APButton type="negative" size="small" onClick={() => { }} >Button</APButton>
                        <APButton leading={<ChevronRight />} type="negative" onClick={() => { }} >Button</APButton>
                        <APButton type="negative" size="large" onClick={() => { }} >Button</APButton>
                        <APButton type="negative" size="large">Button</APButton>
                    </APRow>
                </DemoItem>
                <DemoItem label="APAsyncButton">
                    <APRow mainAxisSize="max" mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APAsyncButton type="primary" onClick={async () => { await sleep(4000) }}>Outlined</APAsyncButton>
                        <APAsyncButton type="secondary" onClick={async () => { await sleep(4000) }}>This is a long text</APAsyncButton>
                        <APAsyncButton type="negative" onClick={async () => { await sleep(4000) }}>Text</APAsyncButton>
                    </APRow>
                </DemoItem>
                <DemoItem label="APFormSubmit">
                    <APRow mainAxisSize="max" mainAxisAlignment="spaceBetween" gap={"12px"}>
                        <APFormSubmit type="primary" onClick={async () => { await sleep(4000) }}>Outlined</APFormSubmit>
                        <APFormSubmit type="secondary" onClick={async () => { await sleep(4000) }}>This is a long text</APFormSubmit>
                        <APFormSubmit type="negative" onClick={async () => { await sleep(4000) }}>Text</APFormSubmit>
                    </APRow>
                </DemoItem>
            </DemoSection>

            <DemoSection label='Menus/Actions/Options'>
                <APGrid count={2}>
                    <APFilterMenuDemo></APFilterMenuDemo>
                    <APFilterMenuIconDemo></APFilterMenuIconDemo>

                    <DemoItem label="APSwitcher">
                        <APSwitcher onSelect={(option) => { }} options={["Donuts", "Cupcakes", "Pizza"]}></APSwitcher>
                    </DemoItem>
                    <DemoItem label="APMenu">
                        <APMenu icon={<ArrowDropDownCircleOutlined />} label='Menu' options={[
                            {
                                label: "Donuts",
                                callback: () => {
                                    showSnackbar("Donuts");
                                }
                            },
                            {
                                label: "Cupcakes",
                                callback: () => {
                                    showSnackbar("Cupcakes");
                                }
                            },
                        ]}></APMenu>

                    </DemoItem>

                    <DemoItem label="APDropdownSearch">
                        <APDropdownSearch<number>
                            items={
                                NArray(200).map(e => ({
                                    label: `Option ${e + 1}`,
                                    value: e
                                }))
                            }
                            onChange={(v) => {
                                console.log(v);
                            }}
                        />
                    </DemoItem>

                    <DemoItem label="APDropdownMenu">
                        <APDropdownMenu options={["Donuts", "Pizza", "Cupcakes"]} onSelect={(v) => showSnackbar(v)}></APDropdownMenu>

                    </DemoItem>


                    <DemoItem label="APActionsMenu">
                        <APActionsMenu label='Menu' options={[
                            {
                                label: "Donuts",
                                callback: () => {
                                    showSnackbar("Donuts");
                                }
                            },
                            {
                                label: "Cupcakes",
                                callback: () => {
                                    showSnackbar("Cupcakes");
                                }
                            },
                        ]}></APActionsMenu>

                    </DemoItem>


                    <DemoItem label="APMoreMenu">
                        <APMoreMenu variant={"large"} options={[
                            {
                                label: "Donuts",
                                callback: () => {
                                    showSnackbar("Donuts");
                                }
                            },
                            {
                                label: "Cupcakes",
                                callback: () => {
                                    showSnackbar("Cupcakes");
                                }
                            },
                        ]}></APMoreMenu>

                    </DemoItem>

                    <DemoItem label="APDropdownIconMenu">
                        <APDropdownIconMenu options={["Donuts", "Pizza", "Cupcakes"]} onSelect={(v) => showSnackbar(v)}>
                            <Settings></Settings>
                        </APDropdownIconMenu>
                    </DemoItem>
                    <DemoItem label={"APPillButton"} >
                        <APPillButton
                            icon={<MailIcon />}
                            onClick={() => { console.log("click") }}
                        >
                            New Transaction
                        </APPillButton>
                    </DemoItem>
                </APGrid>
            </DemoSection>

            <DemoSection label='Dropdown Filter'>
                <DemoItem label="Single" >
                    <APRow gap="12px">
                        <APDropdownFilter
                            type="single"
                            label="Single"
                            options={[
                                { label: "All", value: "all" },
                                { label: "Active", value: "active" },
                                { label: "Inactive", value: "inactive" },
                            ]}
                            onChange={(v) => { }}
                        />
                        <APDropdownFilter
                            type="single"
                            initialValue={"active"}
                            label="Single"
                            options={[
                                { label: "All", value: "all" },
                                { label: "Active", value: "active" },
                                { label: "Inactive", value: "inactive" },
                            ]}
                            onChange={(v) => { }}
                        />
                    </APRow>
                </DemoItem>
                <DemoItem label="Multi" >
                    <APRow gap="12px">
                        <APDropdownFilter
                            type="multi"
                            label="Multi"
                            options={[
                                { label: "All", value: "all" },
                                { label: "Active", value: "active" },
                                { label: "Inactive", value: "inactive" },
                            ]}
                            onChange={(v) => { }}
                        />
                        <APDropdownFilter
                            type="multi"
                            initialValue={["active", "inactive", "asdfasdf"]}
                            label="Multi"
                            options={[
                                { label: "All", value: "all" },
                                { label: "Active", value: "active" },
                                { label: "Inactive", value: "inactive" },
                            ]}
                            onChange={(v) => { }}
                        />
                    </APRow>
                </DemoItem>
                <DemoItem label="Grouped" >
                    <APGroupedDropdownFilter
                        options={[
                            {
                                label: "Multi",
                                type: "multi",
                                options: [
                                    { label: "All", value: "all" },
                                    { label: "Active", value: "active" },
                                    { label: "Inactive", value: "inactive" },
                                ],
                                onChange: (v) => {
                                }
                            },
                            {
                                type: "single",
                                label: "Single",
                                options: [
                                    { label: "All", value: "all" },
                                    { label: "Active", value: "active" },
                                    { label: "Inactive", value: "inactive" },
                                ],
                                onChange: (v) => {
                                }
                            }
                        ]}
                    />
                </DemoItem>
            </DemoSection>

        </DemoPage>
    )
}


function APFilterMenuDemo() {

    const [priority, setPriority] = useState('YES');
    const [advisor, setAdvisor] = useState(["ABC Planners", "Test advisor"]);
    const [dateRange, setDateRange] = useState<APDateRange>({});
    const [range, setRange] = useState<APNumberRange>({ min: 0, max: 100 });
    const [amount, setAmount] = useState<APAmountRange>({ min: undefined, max: undefined });

    return (
        <DemoItem label="APFilterMenu">
            <APFilterMenu
                headerWidth={200}
                label='Filter'
                options={[
                    {
                        title: 'Priority',
                        type: 'single',
                        options: ["All", "ABC Planners", "Test advisor", "Priority", "Lorem", "ipsum", "dolor", "sit", "amet,", "consectetur", "adipiscing", "elit.", "Donec", "rutrum", "consequat", "nisi,", "sed", "aliquam", "arcu", "rhoncus", "vel.", "Cras", "nulla", "elit,", "scelerisque", "at", "diam", "ut,", "tempus", "laoreet", "neque.", "In", "metus", "augue,", "vehicula", "eu", "efficitur", "non,", "tincidunt", "sed", "magna.", "Mauris", "dui", "sem,", "ornare", "ultricies", "luctus", "sit", "amet,", "sodales", "sit", "amet", "tortor.", "Mauris", "orci", "odio,", "faucibus", "vitae", "laoreet", "lobortis,", "convallis", "vitae", "dui.", "Nunc", "vitae", "odio", "venenatis,", "ornare", "ligula", "tempor,"].map(e => ({ label: e, value: e })),
                        initialValue: priority,
                        onChange: setPriority
                    },
                    {
                        title: 'Advisor',
                        type: 'multi',
                        options: ["All", "ABC Planners", "Test advisor", "Priority", "Lorem", "ipsum", "dolor", "sit", "amet,", "consectetur", "adipiscing", "elit.", "Donec", "rutrum", "consequat", "nisi,", "sed", "aliquam", "arcu", "rhoncus", "vel.", "Cras", "nulla", "elit,", "scelerisque", "at", "diam", "ut,", "tempus", "laoreet", "neque.", "In", "metus", "augue,", "vehicula", "eu", "efficitur", "non,", "tincidunt", "sed", "magna.", "Mauris", "dui", "sem,", "ornare", "ultricies", "luctus", "sit", "amet,", "sodales", "sit", "amet", "tortor.", "Mauris", "orci", "odio,", "faucibus", "vitae", "laoreet", "lobortis,", "convallis", "vitae", "dui.", "Nunc", "vitae", "odio", "venenatis,", "ornare", "ligula", "tempor,"].map(e => ({ label: e, value: e })),
                        initialValue: advisor,
                        onChange: setAdvisor
                    },
                    {
                        title: "Signup Date",
                        type: 'dateRange',
                        initialValue: dateRange,
                        onChange: setDateRange
                    },
                    {
                        title: "Purchase Range",
                        type: 'range',
                        totalRange: { min: 0, max: 100 },
                        initialValue: range,
                        onChange: setRange
                    },
                    {
                        title: "Purchase Amount",
                        type: 'amount',
                        initialValue: amount,
                        onChange: setAmount
                    }
                ]}
            />

            <APSizedBox height="24px" />

            <APFilterMenuV2
                label='Advanced Filter'
                options={[
                    {
                        title: 'Priority',
                        type: 'single',
                        options: ["All", "Yes", "No"].map(e => ({ label: e, value: e })),
                        initialValue: priority,
                    },
                    {
                        title: 'Advisor',
                        type: 'multi',
                        options: ["All", "ABC Planners", "Test advisor", "Abhishek"].map(e => ({ label: e, value: e })),
                        initialValue: advisor,
                    },
                    {
                        title: "Signup Date",
                        type: 'dateRange',
                        initialValue: dateRange,
                    },
                    {
                        title: "Purchase Range",
                        type: 'range',
                        totalRange: { min: 0, max: 100 },
                        initialValue: range,
                    },
                    {
                        title: "Purchase Amount",
                        type: 'amount',
                        initialValue: amount,
                    }
                ]}
                onChange={(v: any[]) => {
                    console.log(v);
                }}
            />
        </DemoItem>
    )
}


function APFilterMenuIconDemo() {

    const [priority, setPriority] = useState('YES');
    const [advisor, setAdvisor] = useState(["ABC Planners", "Test advisor"]);

    return (
        <DemoItem label="APFilterMenu">
            <APFilterMenu
                label='Filter'
                icon={<SettingsOutlined /* htmlColor="#666" */ fontSize={"small"}></SettingsOutlined>}
                options={[
                    {
                        title: 'Priority',
                        type: 'single',
                        options: [
                            { label: "All", value: "ALL" },
                            { label: "Yes", value: "YES" },
                            { label: "No", value: "NO" },
                        ],
                        initialValue: priority,
                        onChange: setPriority
                    },
                    {
                        title: 'Advisor',
                        type: 'multi',
                        options: ["All", "ABC Planners", "Test advisor", "Priority"].map(e => ({ label: e, value: e })),
                        initialValue: advisor,
                        onChange: setAdvisor
                    }
                ]}
            />
        </DemoItem>
    )
}

